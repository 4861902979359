/* You can add global styles to this file, and also import other style files */

@import "./variables";

/* Importing Bootstrap SCSS file. */
// Layout & components
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/offcanvas";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/placeholders";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/type";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";
// scss-docs-end import-stack

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

@import '~ngx-toastr/toastr';

body {
  font-family: 'Source Sans Pro';

  a, .alpha-sort {
    color: $link-color;
    text-decoration: none;
  
    &:hover {
      color: $skinpro-brand-link;
    }
  }

  @media screen and (min-width: 768px) {
    .ng-trigger-routeAnimations {
      height: 100%;
    }
  }
}

h1, h2, h3 {
  font-family: 'Cormorant';
}

app-header {
  background-color: $header-bg;
  border-bottom: 2px solid $header-bottom;
}

app-nav-bottom {
  background-image: url(assets/images/background_web_lg.jpg);
  z-index: -1;
}

.dropdown-page {
  padding-top: 1em;
  padding-bottom: 4em;
}

.border-start {
  border-left: 3px solid $skinpro-brand-link !important; // need to supercede Bootstrap's rule
  display: inline-block;
  height: $title-tick-height;
  top: $title-tick-top;
}

.nav-background-img {
  background-image: url(assets/images/background_web_lg.jpg);
  z-index: -1;
}

.nav-background-md {
  @media screen and (min-width: $md) {
    background-color: #ffffff;
  }
  @media screen and (max-width: ($md - 1)) {
    background-image: url(assets/images/background_web_lg.jpg);
    z-index: -1;
  }
}

.mobile-background {
  background-image: url(assets/images/background_mobile.jpg);
  z-index: -1;
}

.web-background-md {
  @media screen and (min-width: $md) {
    background-image: url(assets/images/background_web_lg.jpg);
    z-index: -1;
  }
}

.left-panel {
  margin-top: 0.75em;
}

.smaller-text {
  font-size: 95%;
}
