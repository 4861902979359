// Variable Overrides
$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;

$primary: #2668B4; // main blue
$secondary: #a0b7bc; // gray
$success: #37bd74;
$danger: #e8286b;
$info: #1BC4C7; // turquoise
$warning: #ff9b37;
$light: #eef0f2;
$dark: #002248;
$footer: #D09B92;

// Other colors
$blue:    $primary !default;
$dark-blue:   $dark !default;
$dark-blue-bg:   #000b1f !default;
$blue-highlight:   #2699FB !default;

// Basics
$sm: 576px;
$md: 768px;
$mobile-width-xs: 576px;
$mobile-width-xxs: 375px;

$body-bg: $white;
$body-color: $black;
$border-width: 1px;
$btn-border-radius: 0.25rem;
$content-card-border: #ffffff;
$font-family-base: 'Open Sans', sans-serif;
$footer-link-hover: #617881;
$footer-nav-icons: #617881;
$header-bg: $white;
$header-bottom: #cacacc;
$header-logo-width: 210px;
$header-logo-width-xs: 172px;
$link-color: #16495d;
$navbar-bottom-height: 1.625rem;
$search-bar-left: #85888c;
$search-bar-icon: #ffffff;
$spacer: 1rem;
$skinpro-brand-link: #d09b92;
$title-tick-height: 0.75em;
$title-tick-top: 0.25em;

// Component/page/route specific
$accordion-card-bg: #eaeaee;
$accordion-card-body-bg: #ffffff;
$accordion-mb: 6em;
$back-btn: #5e666a;
$btn-remove-bg: #eaeaee;
$card-body-padding: 0.75rem;
$card-title-header: 1.36rem;
$card-title-header-mb: 0.25rem;
$content-title: 1.5em;
$content-icon-mr: 0.25em;
$content-list-btn: 0.835rem;
$content-list-header: 176%;
$content-search-header: 30pt;
$dropdown-menu-icon: #525a5f;
$fave-btn-bg: #f1f2f4;
$fave-btn-border: #f0f2f3;
$loading-logo-width: 250px;
$search-placeholder: #cacacc;
$tabs-header: 1.2em;
$tab-pane-pt: 1em;

// Jumbotron
$jumbotron-padding: 1rem;
$jumbotron-bg: rgba($gray-200, .75);

// Tabs
$nav-tabs-link-active-color: $black;
$nav-tabs-link-active-bg: $gray-200;

// Bootstrap Required
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// Calculated
$top-header-height-rem: ($navbar-brand-padding-y * 2) + ($navbar-padding-y * 2);
$top-header-height-px: 82.88px + 2px;
$top-header-height-px-xs: 74px;
$bottom-nav-height-rem: (1.5rem * 1.2) + ($btn-font-size-lg * .75) + $headings-margin-bottom + .25rem + (.35rem * 2);
$bottom-nav-height-px: ($btn-border-width * 2) + 2px;
$bottom-footer-height: ($font-size-base * $line-height-base) + (map-get($spacers, 1) * 2);
$bottom-footer-height-sm: ($font-size-base * .75 * $line-height-base) + (map-get($spacers, 1) * 2);
